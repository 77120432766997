@font-face {
  font-family: "Rijksoverheid Sans Web Text";
  src: url("./ROsanswebtextbold.woff") format("woff"),
    url("./ROsanswebtextbold.ttf") format("ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Rijksoverheid Sans Web Text";
  src: url("./ROsanswebtextitalic.woff") format("woff"),
    url("./ROsanswebtextitalic.ttf") format("ttf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Rijksoverheid Sans Web Text";
  src: url("./ROsanswebtextregular.woff") format("woff"),
    url("./ROsanswebtextregular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Rijksoverheid Serif Web";
  src: url("./ROserifwebbold.eot") format("eot"),
    url("./ROserifwebbold.ttf") format("ttf"),
    url("./ROserifwebbold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Rijksoverheid Serif Web";
  src: url("./ROserifwebitalic.eot") format("eot"),
    url("./ROserifwebitalic.ttf") format("ttf"),
    url("./ROserifwebitalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Rijksoverheid Serif Web";
  src: url("./ROserifwebregular.eot") format("eot"),
    url("./ROserifwebregular.ttf") format("ttf"),
    url("./ROserifwebregular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
